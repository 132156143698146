import React, {useEffect} from 'react';

import Header from './Header';
import PricingTables from './PricingTables';
import FeaturesTable from './FeaturesTable';
// import TestimonialsCarousel from 'TestimonialsCarousel';
import Faqs from './Faqs';
import Cta from './Cta';
import Footer from './Footer';

function Pricing() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <div  className="flex flex-col min-h-screen overflow-hidden">

        {/*  Page content */}
        <main className="flex-grow">

          {/*  Page sections */}
          <div className="pricing">
            <PricingTables />

            {/* <FeaturesTable /> */}
            {/* <TestimonialsCarousel /> */}
            {/* <Faqs />
            <Cta />    */}
          </div>

        </main>

      </div>


    </div>
  );
}

export default Pricing;